const MediaTypeOptions = [
  //TODO:Billboardに統一される時に修正する。visionについても同様。
  //TODO:ここのvalueをクエリパラメータにしていると思うんだけど、urlの長さはできるだけ簡潔な方がいいから、queryみたいなフィールドを作るor別で定数を作る。そして、billbord,visionみたいな簡潔な値を用意してあげた方がいいと思う。
  { key: 'mediaTypeBillboard', text: 'ビルボード', value: 'mediaTypeBillboard' },
  { key: 'mediaTypeVision', text: 'デジタルビジョン', value: 'mediaTypeVision' },
  { key: 'mediaTypeLeaflet', text: 'リーフレット', value: 'mediaTypeLeaflet' },
  { key: 'mediaTypeMansionSignage', text: 'マンションサイネージ', value: 'mediaTypeMansionSignage' },
  { key: 'mediaTypePoster', text: 'ポスター', value: 'mediaTypePoster' },
  { key: 'mediaTypeJack', text: 'エリアジャック', value: 'mediaTypeJack' },
  { key: 'mediaTypeAdTruck', text: 'アドトラック', value: 'mediaTypeAdTruck' },
]

export default MediaTypeOptions
