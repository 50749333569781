import { SexPieChartDispMethod, AgePieChartDispMethod, SexRatioByAgeChartDispMethod, bool2Has, getCol, directionDisplayMethod, parseArea } from './helpers'
import { daysInWeek } from 'constants/constants'
import { taxRate as defaultTaxRate } from 'constants/constants'
import { getPeriod } from 'helpers/mediaType/helpers'
import { types } from 'constants/types'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

export const mediaTypeVision = {
  name: 'デジタルサイネージ',
  basicInfoFields: [
    {
      displayedName: '住所',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'string',
      displayMethod: getCol('address'),
    },
    {
      displayedName: '緯度',
      displayedPrefix: 'N',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('latitude'),
    },
    {
      displayedName: '経度',
      displayedPrefix: 'E',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('longitude'),
    },
    {
      displayedName: '週額掲載費',
      displayedPrefix: '¥',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPraivate ? '価格非公開' : isJackChild(media) ? '-' : roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1),
    },
    {
      displayedName: '向き',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: directionDisplayMethod,
    },
    {
      displayedName: '音声',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'bool',
      displayMethod: (media) => bool2Has(media['hasAudio']),
    },
    {
      displayedName: 'エリア',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: (media) => parseArea(media['address']),
    },
  ],
  //TODO:媒体一覧かカート編集かによって表示する項目自体も変わる
  effectIndicatorFields: [
    {
      fieldName: 'circulationPerDay',
      displayedName: '歩行者数',
      displayedPrefix: '',
      displayedSuffix: '',
      summary: null,
      displayMethod: {
        mediaSearch: (media) => roundAndAddThousandsSeparator(media.circulationPerDay * daysInWeek, 1),
        cartEdit: null,
      },
    },
    {
      fieldName: '価格／歩行者',
      displayedName: '価格／歩行者 ※１',
      displayedPrefix: '¥',
      displayedSuffix: '',
      summary: {
        mediaSearch: '※１：価格／歩行者は、4週間掲載した際の歩行者1人あたりのコスト（税込みでの掲載価格＋施工回復費）。表示される価格は全て税込み。',
        cartEdit:
          '※１：価格／歩行者は、4週間掲載した際の歩行者1人あたりのコスト（税込みでの掲載価格＋施工回復費）。表示される価格は全て税込み。\n※２：tCPM（targeting Cost Per Mille）は、指定ターゲットへの1000インプレッションあたりのコスト。',
      },
      displayMethod: {
        mediaSearch: (media) =>
          media.isPrivate
            ? '価格非公開'
            : isJackChild(media)
            ? '-'
            : `¥${roundAndAddThousandsSeparator(calcVisionCostEffectiveness(media, 4, false, true), 1)}`,
        cartEdit: null,
      },
    },
  ],
  // 媒体がたくさん表示される画面で表示される小さなカードに載せる値
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格/週',
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcVisionCost(media, 4, true, false), 1)}`),
        prefix: '¥',
        suffix: '',
      },
      {
        displayedName: '歩行者数/週',
        displayMethod: (media) => roundAndAddThousandsSeparator(media.circulationPerDay * daysInWeek, 1) + '人',
        prefix: '',
        suffix: '',
      },
      {
        displayedName: '価格/歩行者',
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcVisionCostEffectiveness(media, 4, false, true), 10000)}`), //そのままroundすると0で比較できないので、少数第三位まで記載
        prefix: '¥',
        suffix: '',
      },
    ],
    cartEdit: [
      {
        displayedName: '価格/週',
        displayMethod: (media, period) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcVisionCost(media, period.weeks, true, false), 1)}`),
        prefix: '¥',
        suffix: '',
      },
      {
        displayedName: '歩行者数/週',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => roundAndAddThousandsSeparator(media.circulationPerDay * daysInWeek, 1),
        prefix: '',
        suffix: '',
      },
      {
        displayedName: '価格/歩行者',
        prefix: '¥',
        suffix: '',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcVisionCostEffectiveness(media, period.weeks, false, true), 10000)}`, //そのままroundすると0で比較できないので、少数第三位まで記載
      },
    ],
  },
  cartEditMediaDetailEffectTable: [
    {
      displayedName: '歩行者数/週',
      displayMethod: (args) => (!isNaN(args.media.circulationPerDay) ? roundAndAddThousandsSeparator(args.media.circulationPerDay * daysInWeek, 1) : 'N/A'),
    },
    {
      displayedName: 'ターゲット割合',
      displayMethod: (args) =>
        !isNaN(args.media.targetCirculationPerDay / args.media.circulationPerDay)
          ? `${Math.round((args.media.targetCirculationPerDay / args.media.circulationPerDay) * 100)}%`
          : 'N/A',
    },
    {
      displayedName: '価格／歩行者',
      displayMethod: (args) =>
        args.isPrivate
          ? '非公開'
          : isJackChild(args.media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(calcVisionCostEffectiveness(args.media, args.period.weeks, false, true), 1)}`,
    },
    {
      displayedName: '視認率',
      displayMethod: (args) => (!isNaN(args.media.visibility) ? `${Number(args.media.visibility * 100).toFixed(2)}%` : 'N/A'),
    },
  ],
  cartFooterContent: [
    {
      displayedName: '総歩行者数',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalCirculationPerWeek) ? roundAndAddThousandsSeparator(bundleSummary.totalCirculationPerWeek, 1) + '人' : '-',
    },
    {
      displayedName: 'ターゲット割合',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalTargetCirculationPerWeek / bundleSummary.totalCirculationPerWeek)
          ? Math.round((bundleSummary.totalTargetCirculationPerWeek / bundleSummary.totalCirculationPerWeek) * 100) + '%'
          : '-',
    },
    {
      displayedName: '週額費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalOverallCostForWeek) ? `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForWeek, 1)}` : '-',
    },
    {
      displayedName: '価格／歩行者',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.costEffectiveness) ? `¥${roundAndAddThousandsSeparator(bundleSummary.costEffectiveness, 10000)}` : '-',
    },
  ],
  cartRecommendSummary: [
    {
      displayedName: '週額費用',
      displayMethod: (bundleSummary) => (!isNaN(bundleSummary.totalCost) ? `¥${bundleSummary.totalCost.toLocaleString()}` : '-'),
    },
    {
      displayedName: '歩行者数／週',
      displayMethod: (bundleSummary) => roundAndAddThousandsSeparator(bundleSummary.totalCirculation, 1) + '人',
    },
    {
      displayedName: '価格／歩行者',
      displayMethod: (bundleSummary) => (bundleSummary.costEffectiveness ? `¥${roundAndAddThousandsSeparator(bundleSummary.costEffectiveness, 1)}` : '-'),
    },
  ],
  propertyFields: [
    {
      fieldName: 'totalSexRatio',
      displayedName: '性別',
      graphType: 'pie',
      displayMethod: SexPieChartDispMethod,
    },
    {
      fieldName: 'ageRatio',
      displayedName: '年齢',
      graphType: 'pie',
      displayMethod: AgePieChartDispMethod,
    },
    {
      fieldName: 'sexRatioByAge',
      displayedName: '年代別性別比',
      graphType: 'stackedBar',
      displayMethod: SexRatioByAgeChartDispMethod,
    },
  ],
  cartConfirmCard: {
    header: ['媒体', '掲載週数', '単週掲載費', '小計'],
    contents: [
      {
        displayedName: '掲載週数',
        displayMethod: (media, period) => period.weeks + '週間',
      },
      {
        displayedName: '単週掲載費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => {
          return isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`
        },
      },
      {
        displayedName: '小計',
        displayMethod: (media, period) => {
          return isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcVisionCost(media, period.weeks, true, true), 1)}`
        },
      },
    ],
  },
  cartConfirmSummary: [
    {
      displayedName: '媒体数',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeVision) => mediaSortedList.length,
    },
    {
      displayedName: '掲載期間',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeVision) => getPeriod(cart),
    },
    {
      displayedName: '掲載費合計(税抜)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeVision) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod, 1)}`,
    },
    {
      displayedName: '消費税',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeVision) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * (defaultTaxRate - 1), 1)}`,
    },
    {
      displayedName: '合計金額(税込)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeVision) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
  ],
  cartConfirmFooter: [
    {
      displayedName: '合計サーキュレーション',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalCirculationPerWeek) ? roundAndAddThousandsSeparator(bundleSummary.totalCirculationPerWeek, 1) + '人' : '-',
    },
    {
      displayedName: 'ターゲットの合計サーキュレーション',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalCirculationPerWeek) ? roundAndAddThousandsSeparator(bundleSummary.totalTargetCirculationPerWeek, 1) + '人' : '-',
    },
    {
      displayedName: '掲載費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalOverallCostForWeek)
          ? `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod * defaultTaxRate, 1)}`
          : '-',
    },
    {
      displayedName: '施工回復費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.totalOverallCostForWeek) ? `¥${roundAndAddThousandsSeparator(0 * defaultTaxRate, 1)}` : '-',
    },
    {
      displayedName: '価格／歩行者',
      displayMethod: (bundleSummary: bundleSummaryDTypeVision) =>
        !isNaN(bundleSummary.costEffectiveness) ? `¥${roundAndAddThousandsSeparator(bundleSummary.costEffectiveness, 10000)}` : '-',
    },
  ],
  culcBundleSummary: (mediaList, period, isTaxIncluded) => {
    const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
    const bundleSummary: bundleSummaryDTypeVision = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForWeek: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForWeek: 0,
      totalCirculationPerWeek: 0,
      totalTargetCirculationPerWeek: 0,
      costEffectiveness: 0,
      targetCostEffectiveness: 0,
      mediaType: 'mediaTypeVision',
    }
    mediaList
      .filter((medium) => !isJackChild(medium))
      .forEach((medium) => {
        bundleSummary.totalPublicationCostForWeek += medium.weeklyCost * medium.marginRate * taxRate
        bundleSummary.totalCirculationPerWeek += medium.circulationPerDay * daysInWeek
        bundleSummary.totalTargetCirculationPerWeek += medium.targetCirculationPerDay * medium.visibility * daysInWeek
      })
    bundleSummary.totalPublicationCostForEntirePeriod += bundleSummary.totalPublicationCostForWeek * period.weeks
    bundleSummary.totalOverallCostForEntirePeriod = bundleSummary.totalPublicationCostForEntirePeriod
    bundleSummary.totalOverallCostForWeek = bundleSummary.totalOverallCostForEntirePeriod / period.weeks
    bundleSummary.costEffectiveness =
      !isNaN(bundleSummary.totalOverallCostForWeek) && !isNaN(bundleSummary.totalCirculationPerWeek)
        ? bundleSummary.totalOverallCostForWeek / bundleSummary.totalCirculationPerWeek
        : NaN
    bundleSummary.targetCostEffectiveness =
      !isNaN(bundleSummary.totalOverallCostForWeek) && !isNaN(bundleSummary.totalCirculationPerWeek)
        ? bundleSummary.totalOverallCostForWeek / bundleSummary.totalCirculationPerWeek
        : NaN
    return bundleSummary
  },
  purchasePeriodUnit: 'week',
  cartFields: ['昨日', '今日', '明日', '明後日'],
} as const

export const visionColumnDtypes = {
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  horizontalWidth: types.number,
  verticalWidth: types.number,
  weeklyCost: types.number,
  mediaType: types.enum,
  mediaImagePaths: types.stringArrayElem,
  name: types.string,
  note: types.string,
  summary: types.string,
  uid: types.string,
  isPrivate: types.bool,
  ageRatio: types.string,
  femaleAgeRatio: types.map,
  maleAgeRatio: types.map,
  circulation: types.array,
  circulationStatus: types.enum,
  visibility: types.number,
  visibilityStatus: types.string,
  direction: types.number,
  height: types.number,
  hasAudio: types.bool,
  tempVisibility: types.nullableNumber,
  tempCirculation: types.nullableNumber,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { weeklyCost, ...others } = visionColumnDtypes
export const jackChildVisionColumnDtypes = { ...others, parentUid: types.string }

//mediaTypeVisionの費用対効果計算関数
export const calcVisionCostEffectiveness = (media, weeks, hasTarget: boolean, isTaxIncluded: boolean) => {
  const circulation = hasTarget ? media.targetCirculationPerDay * daysInWeek : media.circulationPerDay * daysInWeek
  const cost = calcVisionCost(media, weeks, isTaxIncluded, false)
  const costEffectiveness = cost / circulation
  return costEffectiveness
}

export const calcVisionCost = (media /*:adviceMediaDataType*/, weeks: number, isTaxIncluded: boolean, isForEntirePeriod: boolean) => {
  const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
  const costForEntirePeriod: number = media.weeklyCost * media.marginRate * weeks * taxRate
  const result = isForEntirePeriod ? costForEntirePeriod : costForEntirePeriod / weeks
  return result
}

// total: 全媒体について合計する
// overall: 掲載費用だけでなく、施工回復費用なので全ての種類の費用を計上する
// forEntirePeriod: 出稿期間全体について費用を算出する
export type bundleSummaryDTypeVision = {
  totalOverallCostForEntirePeriod: number //全ての種類の費用、全ての期間の合計費用
  totalOverallCostForWeek: number //全ての種類の費用、ひと月あたりの費用
  totalPublicationCostForEntirePeriod: number
  totalPublicationCostForWeek: number
  totalCirculationPerWeek: number
  totalTargetCirculationPerWeek: number
  costEffectiveness: number
  targetCostEffectiveness: number
  mediaType: 'mediaTypeVision'
}
