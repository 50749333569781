// 使い方の例
// tmpmedias = MediasSearchFilter(mediasOwn, searchWord)

import { AdviceMediaDataType } from 'constants/mediaData'

/**
 * 媒体リストから条件に当てはまる媒体を抽出して返却する
 */
export const MediasSearchFilter = (
  medias: AdviceMediaDataType[],
  searchWord: string,
  targetTags: string[],
  targetLines: string[],
  targetHighways: string[]
) => {
  const searchWordLower = searchWord.toLocaleLowerCase()

  const tmpMedia = medias.filter((row) => {
    if (row.name.indexOf(searchWordLower) === -1) {
      return false
    }
    if (targetTags.length > 0) {
      let res = false
      if (row.tags && row.tags.length > 0) {
        res = targetTags.every((targetTag) => row.tags.some((tag) => tag.id === targetTag))
      }
      if (!res) {
        return false
      }
    }
    if (targetLines.length > 0) {
      let res = false
      if (row.lines && row.lines.length > 0) {
        res = targetLines.every((targetLine) => row.lines.some((line) => line.id === targetLine))
      }
      if (!res) {
        return false
      }
    }
    if (targetHighways.length > 0) {
      let res = false
      if (row.highways && row.highways.length > 0) {
        res = targetHighways.every((targetHighway) => row.highways.some((highway) => highway.id === targetHighway))
      }
      if (!res) {
        return false
      }
    }
    return true
  })

  return tmpMedia
}
