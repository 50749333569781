export { mediaTypeBillboard } from './mediaTypeBillboard'
import { mediaTypeBillboard } from './mediaTypeBillboard'

export { mediaTypeVision } from './mediaTypeVision'
import { mediaTypeVision } from './mediaTypeVision'

export { mediaTypeLeaflet } from './mediaTypeLeaflet'
import { mediaTypeLeaflet } from './mediaTypeLeaflet'

export { mediaTypeMansionSignage } from './mediaTypeMansionSignage'
import { mediaTypeMansionSignage } from './mediaTypeMansionSignage'

export { mediaTypeJack } from './mediaTypeJack'
import { mediaTypeJack } from './mediaTypeJack'
import { mediaTypeAdTruck } from './mediaTypeAdTruck'

export const getColInfoFromTypeName = (mediaTypeStr: string) => {
  switch (mediaTypeStr) {
    case 'mediaTypeBillboard':
      return mediaTypeBillboard
    case 'mediaTypeVision':
      return mediaTypeVision
    case 'mediaTypeLeaflet':
      return mediaTypeLeaflet
    case 'mediaTypeMansionSignage':
      return mediaTypeMansionSignage
    case 'mediaTypeJack':
      return mediaTypeJack
    case 'mediaTypeAdTruck':
      return mediaTypeAdTruck
    default:
      throw TypeError('Received unknown mediaType: ' + String(mediaTypeStr))
  }
}
