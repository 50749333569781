import { FirestoreAdTruckDataType, FirestoreMediaDataType, isFirestoreAdTruckDataType } from './../helpers/mediaType/mediaDef'
import { tagDataType } from 'providers/Tags'
import {
  FirestorePosterDataType,
  FirestoreBillboardDataType,
  FirestoreVisionDataType,
  FirestoreLeafletDataType,
  FirestoreMansionSignageDataType,
  FirestoreJackDataType,
  isFirestoreMediaDataType,
  isFirestoreBillboardDataType,
  isFirestoreVisionDataType,
  isFirestoreLeafletDataType,
  isFirestoreMansionSignageDataType,
  isFirestorePosterDataType,
  isFirestoreJackDataType,
} from 'helpers/mediaType/mediaDef'
import { priceSetting } from 'FirebaseConfig'

export const convertMedia = (firestoreMediaObj: unknown): AdviceMediaDataType => {
  if (isFirestoreMediaDataType(firestoreMediaObj)) {
    const marginRate = priceSetting.defaultMarginRate
    const docId: string = firestoreMediaObj.uid
    const description: string = firestoreMediaObj.note
    const { maleAgeRatio, femaleAgeRatio, ageRatio } = firestoreMediaObj
    const maleRatio: number = maleAgeRatio && maleAgeRatio['total']
    const maleRatio15: number = maleAgeRatio && maleAgeRatio['15']
    const maleRatio20: number = maleAgeRatio && maleAgeRatio['20']
    const maleRatio30: number = maleAgeRatio && maleAgeRatio['30']
    const maleRatio40: number = maleAgeRatio && maleAgeRatio['40']
    const maleRatio50: number = maleAgeRatio && maleAgeRatio['50']
    const maleRatio60: number = maleAgeRatio && maleAgeRatio['60']
    const maleRatio70: number = maleAgeRatio && maleAgeRatio['70']
    const maleRatio80: number = maleAgeRatio && maleAgeRatio['80']
    const femaleRatio: number = femaleAgeRatio && femaleAgeRatio['total']
    const femaleRatio15: number = femaleAgeRatio && femaleAgeRatio['15']
    const femaleRatio20: number = femaleAgeRatio && femaleAgeRatio['20']
    const femaleRatio30: number = femaleAgeRatio && femaleAgeRatio['30']
    const femaleRatio40: number = femaleAgeRatio && femaleAgeRatio['40']
    const femaleRatio50: number = femaleAgeRatio && femaleAgeRatio['50']
    const femaleRatio60: number = femaleAgeRatio && femaleAgeRatio['60']
    const femaleRatio70: number = femaleAgeRatio && femaleAgeRatio['70']
    const femaleRatio80: number = femaleAgeRatio && femaleAgeRatio['80']
    const ratio15: number = ageRatio && ageRatio['15']
    const ratio20: number = ageRatio && ageRatio['20']
    const ratio30: number = ageRatio && ageRatio['30']
    const ratio40: number = ageRatio && ageRatio['40']
    const ratio50: number = ageRatio && ageRatio['50']
    const ratio60: number = ageRatio && ageRatio['60']
    const ratio70: number = ageRatio && ageRatio['70']
    const ratio80: number = ageRatio && ageRatio['80']
    const ratioObject = {
      maleRatio,
      maleRatio15,
      maleRatio20,
      maleRatio30,
      maleRatio40,
      maleRatio50,
      maleRatio60,
      maleRatio70,
      maleRatio80,
      femaleRatio,
      femaleRatio15,
      femaleRatio20,
      femaleRatio30,
      femaleRatio40,
      femaleRatio50,
      femaleRatio60,
      femaleRatio70,
      femaleRatio80,
      ratio15,
      ratio20,
      ratio30,
      ratio40,
      ratio50,
      ratio60,
      ratio70,
      ratio80,
    }
    const tags: tagDataType[] = []
    const isAtCart = false
    switch (firestoreMediaObj.mediaType) {
      case 'mediaTypeVision':
        if (isFirestoreVisionDataType(firestoreMediaObj)) {
          const heightPosition: number = firestoreMediaObj.height
          const widthSize: number = firestoreMediaObj.horizontalWidth
          const heightSize: number = firestoreMediaObj.verticalWidth
          const circulationPerDay = 0
          //TODO:objectを宣言してから代入していけば記述量を減らせるが、asを使うと型安全でなくなってしまう...ので宣言と同時に中身も代入している。何か良い方法があれば改善。
          const adviceMediaObj: AdviceVisionDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            heightPosition,
            widthSize,
            heightSize,
            circulationPerDay,
            tags,
            isAtCart,
            mediaType: 'mediaTypeVision',
          }
          hidePrice(adviceMediaObj)
          updateAdresResultWithTempValue(adviceMediaObj, firestoreMediaObj)
          return adviceMediaObj
        } else {
          throw new Error('isFirestoreVisionDataTypeを満たしません。')
        }
      case 'mediaTypeBillboard':
        if (isFirestoreBillboardDataType(firestoreMediaObj)) {
          const heightPosition: number = firestoreMediaObj.height
          const widthSize: number = firestoreMediaObj.horizontalWidth
          const heightSize: number = firestoreMediaObj.verticalWidth
          const impressionPerDay = 0
          const circulationPerDay = 0
          const adviceMediaObj: AdviceBillboardDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            heightPosition,
            widthSize,
            heightSize,
            impressionPerDay,
            circulationPerDay,
            tags,
            isAtCart,
            mediaType: 'mediaTypeBillboard',
          }
          //isPrivate===trueの場合、価格をnullにする関数
          hidePrice(adviceMediaObj)
          //impressionとcirculationについてtempのものがあればそれを採用する関数
          updateAdresResultWithTempValue(adviceMediaObj, firestoreMediaObj)
          return adviceMediaObj
        } else {
          throw new Error('isFirestoreBillboardDataTypeを満たしません。')
        }
      case 'mediaTypeLeaflet':
        if (isFirestoreLeafletDataType(firestoreMediaObj)) {
          const adviceMediaObj: AdviceLeafletDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            tags,
            isAtCart,
            mediaType: 'mediaTypeLeaflet',
          }
          hidePrice(adviceMediaObj)
          return adviceMediaObj
        } else {
          throw new Error('isFirestoreLeafletDataTypeを満たしません。')
        }
      case 'mediaTypeMansionSignage':
        if (isFirestoreMansionSignageDataType(firestoreMediaObj)) {
          const widthSize: number = firestoreMediaObj.horizontalWidth
          const heightSize: number = firestoreMediaObj.verticalWidth
          const adviceMediaObj: AdviceMansionSignageDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            widthSize,
            heightSize,
            tags,
            isAtCart,
            mediaType: 'mediaTypeMansionSignage',
          }
          hidePrice(adviceMediaObj)
          return adviceMediaObj
        } else {
          throw new Error('isFirestoreMansionSignageDataTypeを満たしません。')
        }
      case 'mediaTypePoster':
        if (isFirestorePosterDataType(firestoreMediaObj)) {
          const heightPosition: number = firestoreMediaObj.height
          const widthSize: number = firestoreMediaObj.horizontalWidth
          const heightSize: number = firestoreMediaObj.verticalWidth
          const impressionPerDay = 0
          const circulationPerDay = 0
          const adviceMediaObj: AdvicePosterDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            heightPosition,
            widthSize,
            heightSize,
            impressionPerDay,
            circulationPerDay,
            tags,
            isAtCart,
            mediaType: 'mediaTypePoster',
          }
          hidePrice(adviceMediaObj)
          updateAdresResultWithTempValue(adviceMediaObj, firestoreMediaObj)
          return adviceMediaObj
        } else {
          throw new Error('isFirestorePosterDataTypeを満たしません。')
        }
      case 'mediaTypeJack':
        if (isFirestoreJackDataType(firestoreMediaObj)) {
          const result: AdviceJackDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            childMedia: [],
            marginRate,
            docId,
            description,
            tags,
            isAtCart,
            mediaType: 'mediaTypeJack',
          }
          hidePrice(result)
          return result
        } else {
          throw new Error('isFirestoreJackDataTypeを満たしません。')
        }
      case 'mediaTypeAdTruck':
        if (isFirestoreAdTruckDataType(firestoreMediaObj)) {
          const result: AdviceAdTruckDataType = {
            ...firestoreMediaObj,
            ...ratioObject,
            marginRate,
            docId,
            description,
            isAtCart,
            tags,
            mediaType: 'mediaTypeAdTruck',
          }
          console.log(result)
          return result
        } else {
          throw new Error('isFirestoreAdTruckDataTypeを満たしません。')
        }
      default:
        throw new Error('未知のmediaTypeです。')
    }
  } else {
    throw new Error('isFirestoreMediaDataTypeを満たしません。')
  }
}

export type AdviceMediaDataType =
  | AdviceBillboardDataType
  | AdviceVisionDataType
  | AdvicePosterDataType
  | AdviceMansionSignageDataType
  | AdviceLeafletDataType
  | AdviceJackDataType
  | AdviceJackChildDataType
  | AdviceAdTruckDataType

type AdviceJackChildDataType =
  | AdviceJackBillboardDataType
  | AdviceJackVisionDataType
  | AdviceJackPosterDataType
  | AdviceJackMansionSignageDataType
  | AdviceJackLeafletDataType

export const isJackChild = (media: unknown): media is AdviceJackChildDataType => {
  if (media && typeof media === 'object' && 'parentUid' in media) {
    return true
  }
  return false
}

type AdviceJackBillboardDataType = Omit<AdviceBillboardDataType, 'monthlyCost' | 'yearlyCost' | 'constructionCost' | 'recoveryCost'> & {
  parentUid: string
}
type AdviceJackVisionDataType = Omit<AdviceVisionDataType, 'weeklyCost'> & {
  parentUid: string
}
type AdviceJackPosterDataType = Omit<AdvicePosterDataType, 'weeklyCost'> & {
  parentUid: string
}
type AdviceJackMansionSignageDataType = Omit<AdviceMansionSignageDataType, 'weeklyCost'> & {
  parentUid: string
}
type AdviceJackLeafletDataType = Omit<AdviceLeafletDataType, 'weeklyCost' | 'distributionCost'> & {
  parentUid: string
}

// mediaTypeにかかわらず、すべての種類の媒体に追加するプロパティ
type AdviceBasicMediaDataType = {
  marginRate: number
  docId: string
  description: string
  maleRatio: number
  maleRatio15: number
  maleRatio20: number
  maleRatio30: number
  maleRatio40: number
  maleRatio50: number
  maleRatio60: number
  maleRatio70: number
  maleRatio80: number
  femaleRatio: number
  femaleRatio15: number
  femaleRatio20: number
  femaleRatio30: number
  femaleRatio40: number
  femaleRatio50: number
  femaleRatio60: number
  femaleRatio70: number
  femaleRatio80: number
  ratio15: number
  ratio20: number
  ratio30: number
  ratio40: number
  ratio50: number
  ratio60: number
  ratio70: number
  ratio80: number
  tags: tagDataType[]
  isAtCart: boolean
}
//isPrivate===trueの時に価格関連のfieldはnullにするため、nullを許容するように変更を加える必要がある。
export type AdviceBillboardDataType = Omit<FirestoreBillboardDataType, 'mediaType' | 'monthlyCost' | 'yearlyCost' | 'constructionCost' | 'recoveryCost'> &
  AdviceBasicMediaDataType & {
    heightPosition: number
    widthSize: number
    heightSize: number
    impressionPerDay: number | undefined
    circulationPerDay: number | undefined
    monthlyCost: number | null
    yearlyCost: number | null
    constructionCost: number | null
    recoveryCost: number | null
    targetCirculationPerDay?: number
    mediaType: 'mediaTypeBillboard'
  }
//isPrivate===trueの時に価格関連のfieldはnullにするため、nullを許容するように変更を加える必要がある。
export type AdviceLeafletDataType = Omit<FirestoreLeafletDataType, 'mediaType' | 'weeklyCost' | 'distributionCost'> &
  AdviceBasicMediaDataType & {
    weeklyCost: number | null
    distributionCost: number | null
    mediaType: 'mediaTypeLeaflet'
  }
//isPrivate===trueの時に価格関連のfieldはnullにするため、nullを許容するように変更を加える必要がある。
export type AdviceVisionDataType = Omit<FirestoreVisionDataType, 'mediaType' | 'weeklyCost'> &
  AdviceBasicMediaDataType & {
    heightPosition: number
    widthSize: number
    heightSize: number
    weeklyCost: number | null
    circulationPerDay: number | undefined
    targetCirculationPerDay?: number
    mediaType: 'mediaTypeVision'
  }
//isPrivate===trueの時に価格関連のfieldはnullにするため、nullを許容するように変更を加える必要がある。
export type AdvicePosterDataType = Omit<FirestorePosterDataType, 'mediaType' | 'weeklyCost' | 'constructionCost' | 'recoveryCost'> &
  AdviceBasicMediaDataType & {
    heightPosition: number
    widthSize: number
    heightSize: number
    weeklyCost: number | null
    constructionCost: number | null
    recoveryCost: number | null
    impressionPerDay: number | undefined
    circulationPerDay: number | undefined
    targetCirculationPerDay?: number
    mediaType: 'mediaTypePoster'
  }
//isPrivate===trueの時に価格関連のfieldはnullにするため、nullを許容するように変更を加える必要がある。
export type AdviceMansionSignageDataType = Omit<FirestoreMansionSignageDataType, 'mediaType' | 'weeklyCost'> &
  AdviceBasicMediaDataType & {
    widthSize: number
    heightSize: number
    weeklyCost: number | null
    mediaType: 'mediaTypeMansionSignage'
  }

export type AdviceJackDataType = Omit<FirestoreJackDataType, 'mediaType'> &
  AdviceBasicMediaDataType & {
    childMedia: AdviceMediaDataType[]
    mediaType: 'mediaTypeJack'
  }

export type AdviceAdTruckDataType = Omit<FirestoreAdTruckDataType, 'mediaType' | 'dailyCost' | 'threeDaysCost' | 'weeklyCost' | 'monthlyCost'> &
  AdviceBasicMediaDataType & {
    dailyCost: number | null
    threeDaysCost: number | null
    weeklyCost: number | null
    monthlyCost: number | null
    mediaType: 'mediaTypeAdTruck'
  }

/**isPrivate===trueの場合、価格をnullにする関数**/
const hidePrice = (mediaObj: AdviceMediaDataType) => {
  if (mediaObj.isPrivate && !isJackChild(mediaObj)) {
    switch (mediaObj.mediaType) {
      case 'mediaTypeBillboard':
        mediaObj.monthlyCost = null
        mediaObj.constructionCost = null
        mediaObj.recoveryCost = null
        mediaObj.yearlyCost = null
        return
      case 'mediaTypeVision':
        mediaObj.weeklyCost = null
        return
      case 'mediaTypeLeaflet':
        mediaObj.weeklyCost = null
        mediaObj.distributionCost = null
        return
      case 'mediaTypeMansionSignage':
        mediaObj.weeklyCost = null
        return
      default:
        return
    }
  }
}
/**impressionとcirculationについてtempのものがあればそれを採用する関数**/
const updateAdresResultWithTempValue = (adviceMediaObj: AdviceMediaDataType, firestoreMediaObj: FirestoreMediaDataType) => {
  //tempVisibilityがあるならば、visibilityとしてそれを採用する
  if ('visibility' in adviceMediaObj && 'tempVisibility' in firestoreMediaObj && firestoreMediaObj.tempVisibility) {
    adviceMediaObj.visibility = adviceMediaObj.tempVisibility
  }
  //totalImpressionがあるならば、impressionPerDayにそれを採用する
  if ('totalImpression' in firestoreMediaObj && 'totalImpression' in adviceMediaObj && firestoreMediaObj.totalImpression) {
    adviceMediaObj.impressionPerDay = firestoreMediaObj.totalImpression
  }
  //tempCirculationとvisibilityがあるならば、impressionPerDayにそれを採用する。一個上の処理は上書きされる。
  if ('tempCirculation' in firestoreMediaObj && 'impressionPerDay' in adviceMediaObj && firestoreMediaObj.tempCirculation && adviceMediaObj.visibility) {
    adviceMediaObj.impressionPerDay = firestoreMediaObj.tempCirculation * adviceMediaObj.visibility
  }
  //tempCirculationがあるならば、circulationPerDayにそれを採用する
  if ('tempCirculation' in firestoreMediaObj && 'circulationPerDay' in adviceMediaObj && firestoreMediaObj.tempCirculation) {
    adviceMediaObj.circulationPerDay = firestoreMediaObj.tempCirculation
  }
  return
}
