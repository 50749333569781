import { db } from 'FirebaseConfig'
import firebase from 'firebase'
const getTagNames = firebase.functions().httpsCallable('getTagNames')
const getLineTags = firebase.functions().httpsCallable('getLineTags')
const getHighwayTags = firebase.functions().httpsCallable('getHighwayTags')
import { getJinryu, getVisibility } from 'helpers/admin/apiHandlers'
import { getGeodata, getBuildingList, safeAlert, readFileAsync, genTimeStamp, CSV2Array, genID, calcRange } from 'helpers/admin/utils'
import { getVisibleRegion, geoData22DArray } from 'helpers/admin/getVisibleRegion'
import calcRatio from 'helpers/admin/calcRatio'
import { mediaTypesEnum } from 'constants/mediaTypes'
import { types } from 'constants/types'
import { leafletColumnDtypes, industryOptions, jackChildLeafletColumnDtypes } from 'helpers/mediaType/mediaTypeLeaflet'
import {
  mansionSignageColumnDtypes,
  majorResidentsOptions,
  mansionTypeOptions,
  jackChildMansionSignageColumnDtypes,
} from 'helpers/mediaType/mediaTypeMansionSignage'
import { jackChildVisionColumnDtypes, visionColumnDtypes } from 'helpers/mediaType/mediaTypeVision'
import { jackColumnDtypes } from 'helpers/mediaType/mediaTypeJack'
import { adTruckColumnDTypes } from 'helpers/mediaType/mediaTypeAdTruck'

export const generateColumnDataTypes = (mediaType, isJackChild = false) => {
  switch (mediaType) {
    case mediaTypesEnum.LEAFLET:
      return isJackChild ? jackChildLeafletColumnDtypes : leafletColumnDtypes
    case mediaTypesEnum.MANSION_SIGNAGE:
      return isJackChild ? jackChildMansionSignageColumnDtypes : mansionSignageColumnDtypes
    case mediaTypesEnum.DIGITAL_VISION:
      return isJackChild ? jackChildVisionColumnDtypes : visionColumnDtypes
    case mediaTypesEnum.JACK:
      return jackColumnDtypes
    case mediaTypesEnum.AD_TRUCK:
      return adTruckColumnDTypes
    default:
      return isJackChild ? defaultJackChildColumnDtypes : defaultColumnDtypes
  }
}

// カラムの値の定義
const defaultColumnDtypes = {
  address: types.string,
  ageRatio: types.string,
  circulation: types.array,
  circulationStatus: types.string,
  constructionCost: types.number,
  direction: types.number,
  femaleAgeRatio: types.map,
  hasIllumination: types.bool,
  height: types.number,
  horizontalWidth: types.number,
  impression: types.array,
  latitude: types.number,
  longitude: types.number,
  maleAgeRatio: types.map,
  //TODO: arrayにして、複数の画像のパスを入力できるようにする
  mediaImagePaths: types.stringArrayElem,
  mediaType: types.enum,
  monthlyCost: types.number,
  name: types.string,
  note: types.string,
  recoveryCost: types.number,
  summary: types.string,
  uid: types.string,
  verticalWidth: types.number,
  visibility: types.number,
  visibilityStatus: types.string,
  yearlyCost: types.number,
  isPrivate: types.bool,
  tempVisibility: types.nullableNumber,
  tempCirculation: types.nullableNumber,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { monthlyCost, yearlyCost, constructionCost, recoveryCost, ...others } = defaultColumnDtypes
export const defaultJackChildColumnDtypes = { ...others, parentUid: types.string }

// firestore内の媒体データに関する仕様を書いたファイル
export const mediaTypes = Object.keys(mediaTypesEnum).map((mediaType) => mediaTypesEnum[mediaType])

// カラムに対して適用する値のチェック関数を入れたオブジェクト
export const validationCallBacks = {
  mediaType: (d) => isValidMediaType(d, mediaTypes),
  direction: (d) => 0 <= d && d <= 360,
  latitude: (d) => -90 <= d && d <= 90,
  longitude: (d) => -180 <= d && d <= 180,
  monthlyCost: (d) => 0 <= d,
  yealryCost: (d) => 0 <= d,
  weeklyCost: (d) => 0 <= d,
  threeDaysCost: (d) => 0 <= d,
  dailyCost: (d) => 0 <= d,
  constructionCost: (d) => 0 <= d,
  recoveryCost: (d) => 0 <= d,
  distributionCost: (d) => 0 <= d,
  height: (d) => 0 <= d,
  verticalWidth: (d) => 0 <= d,
  horizontalWidth: (d) => 0 <= d,
  tempVisibility: (d) => d === null || (0 < d && d < 1),
  tempCirculation: (d) => d === null || 0 < d,
  industry: (d) => industryOptions.map((opt) => opt.value).includes(d),
  numOfHouses: (d) => 0 < d,
  majorResidentsProperty: (d) => {
    return d.every((elem) => majorResidentsOptions.map((opt) => opt.value).includes(elem))
  },
  mansionType: (d) => mansionTypeOptions.map((opt) => opt.value).includes(d),
}

/**
 * カラムcolNameに入っている値dataが正しい値か調べる
 * @param {*} data
 * @param {string} colName
 * @returns bool
 */
export const checkDataContent = (data, colName, validationCallBacks) => {
  if (colName in validationCallBacks) {
    return validationCallBacks[colName](data)
  } else {
    return true
  }
}

/**
 * 受け取った型dtypeをもとに、dataをパースした値を返す
 * @param {*} data
 * @param {*} dtype
 * @returns [object, error]
 */
export const parseData = (data, dtype) => {
  switch (dtype) {
    case types.enum:
    case types.string:
      return [data, null]
    case types.number:
      return [Number(data), null]
    case types.bool:
      return [data.toLowerCase() === 'true', null]
    case types.map:
    case types.array:
      return [JSON.parse(data), null]
    case types.nullableNumber:
      return [isNaN(data) ? null : Number(data), null]
    case types.stringArrayElem:
      return data === '' ? [[], null] : [[data], null]
    default:
      return [null, Error()]
  }
}

/**
 * 受け取ったmediaTypeが正しい値かチェックする
 * @param {*} mediaType
 * @returns bool
 */
const isValidMediaType = (mediaType) => {
  let valid = false
  mediaTypes.forEach((type) => {
    if (type === mediaType) {
      valid = true
    }
  })
  return valid
}
/**
 * カラムに過不足がないかのチェック
 * @param {*} colNames
 */

//カラムの過不足をチェックしているので、型ガードで判定可能
const checkColFormat = (colNames, columnDtypes) => {
  const colNamesAll = Object.keys(columnDtypes)
  //TODO: ここのハードコーディング直したい(tsになったら行けそう)
  const exemptedCols = ['uid', 'femaleAgeRatio', 'maleAgeRatio', 'ageRatio', 'impression', 'visibility', 'visibilityStatus', 'circulation', 'circulationStatus'] // 入力されたcsvファイルの中には入っていなくても構わない列
  const colExistFlag = {} // カラムが存在していたかをチェックするフラグ
  colNamesAll.forEach((colName) => {
    colExistFlag[colName] = false
  })
  exemptedCols.forEach((colName) => {
    colExistFlag[colName] = true
  })
  colNames.forEach((colName) => {
    if (colNamesAll.includes(colName)) {
      colExistFlag[colName] = true
    } else {
      throw 'An invalid column found: ' + colName
    }
  })
  for (const [colName, exist] of Object.entries(colExistFlag)) {
    if (!exist) {
      throw 'Missing a column: ' + colName
    }
  }
}
//値が正しいかなので型ガードに条件を追加すれば対応可能
export const validateMediaForm = (form) => {
  Object.keys(form).forEach((key) => {
    if (!checkDataContent(form[key], key, validationCallBacks)) {
      throw Error('カラム: ' + key + ' に不正な値: ' + form[key] + 'が入っています ')
    }
  })
}

/***
 * csvを読んだ結果の配列dataArray（文字列が入っている）を、データ型columnDtypesに従ってパースし、
 * firestoreのmediaカラムと同じ形式のobjectが入った配列へ変換する
 * エラーが起こった場合は-1を返す
 */
export const parseDataArray = async (dataArray, columnDtypes) => {
  const cols = dataArray[0]
  checkColFormat(cols, columnDtypes)

  const parsedDataArray = []
  let dtypes = cols.map((d) => columnDtypes[d])

  for (let row = 1; row < dataArray.length; row++) {
    let parsedLow = {}
    for (let col = 0; col < dtypes.length; col++) {
      const dtypeTmp = dtypes[col]
      const colNameTmp = cols[col]
      const [val, err] = parseData(dataArray[row][col], dtypeTmp)
      if (err || (typeof val === 'number' && isNaN(val)) || !checkDataContent(val, colNameTmp, validationCallBacks)) {
        throw 'カラム: ' + colNameTmp + ' に不正な値: ' + dataArray[row][col] + 'が入っています '
      }
      parsedLow[colNameTmp] = val
    }
    await addAutofilledCols(parsedLow)
    parsedDataArray.push(parsedLow)
  }
  return parsedDataArray
}

// util funcs
export const convertFile2CSV = async (file, mediaType, isJackChild = false) => {
  if (!file) {
    safeAlert(window, 'ファイルを入力してください')
    return
  }
  file = await readFileAsync(file)
  let data_arr = await CSV2Array(file)
  const columnDtypes = generateColumnDataTypes(mediaType, isJackChild)
  data_arr = await parseDataArray(data_arr, columnDtypes)
  if (data_arr === -1) {
    safeAlert(window, 'データが不適切なため、再度CSVファイルをアップロードしてください。')
    return
  }
  return data_arr
}

const callAdresAPIsHandler = async (mediaId, horizontalWidth, verticalWidth, height, direction, latitude, longitude) => {
  const range = calcRange(horizontalWidth, verticalWidth)
  let geoData = await getGeodata(latitude, longitude, range)
  let buildings = getBuildingList(latitude, longitude, horizontalWidth, range, geoData)
  buildings = geoData22DArray(buildings)
  // eslint-disable-next-line
  let [visiblePoint, __] = await getVisibleRegion(latitude, longitude, height, horizontalWidth, range, direction, buildings)
  const numSurrBoards = 0
  // TODO: 周囲のビルボード数も指定できるようにする
  await getVisibility(mediaId, numSurrBoards, { lat: latitude, lng: longitude }, visiblePoint, range, verticalWidth, horizontalWidth, height, direction)
  await getJinryu(mediaId, visiblePoint)
}

export const addMedia = async (csvData, mediaType) => {
  const promises = []
  for (const docObj of csvData) {
    const batch = db.batch()
    if (mediaType !== mediaTypesEnum.JACK) {
      // Note: tagsコレクションのmediaサブコレクションとmediaコレクションのtagsサブコレクションを使ってレファレンスコレクション(https://qiita.com/1amageek/items/d606dcee9fbcf21eeec6#-reference-collection)を作成する
      // firebaseにカラムが追加されるのを待ってから、視認率や人流量計算のリクエストを投げる

      docObj.tags.forEach((tag) => {
        const tagInMediaRef = db.collection('media').doc(docObj.uid).collection('tags').doc(tag.id)
        batch.set(tagInMediaRef, {
          name: tag.name,
          id: tag.id,
          ref: db.collection('tags').doc(tag.id),
        })
      })
      docObj.lines.forEach((line) => {
        const lineInMediaRef = db.collection('media').doc(docObj.uid).collection('lines').doc(line.lineId)
        batch.set(lineInMediaRef, {
          name: line.lineName,
          id: line.lineId,
          ref: db.collection('lines').doc(line.lineId),
        })
      })
      docObj.highways.forEach((highway) => {
        const highwayInMediaRef = db.collection('media').doc(docObj.uid).collection('highways').doc(highway.id)
        batch.set(highwayInMediaRef, {
          name: highway.name,
          id: highway.id,
          ref: db.collection('highways').doc(highway.id),
        })
      })
    }

    const mediaRef = db.collection('media').doc(docObj.uid)
    //TODO: docObjのうちmediaに追加しなくて良いものもあるのでこれをfileterする必要あり
    batch.set(mediaRef, docObj)
    promises.push(batch.commit())

    if (
      !docObj.isLocalMedia &&
      (mediaType === mediaTypesEnum.BILLBOARD || mediaType === mediaTypesEnum.DIGITAL_VISION || mediaType === mediaTypesEnum.POSTER)
    ) {
      promises.push(
        callAdresAPIsHandler(docObj.uid, docObj.horizontalWidth, docObj.verticalWidth, docObj.height, docObj.direction, docObj.latitude, docObj.longitude)
      )
    }
  }
  return Promise.all(promises)
}

export const addJackChildMedia = async (csvData, mediaType) => {
  const promises = []
  for (const docObj of csvData) {
    const batch = db.batch()
    docObj.tags.forEach((tag) => {
      const tagInMediaRef = db.collection('media').doc(docObj.parentUid).collection('childMedia').doc(docObj.uid).collection('tags').doc(tag.id)
      batch.set(tagInMediaRef, {
        name: tag.name,
        id: tag.id,
        ref: db.collection('tags').doc(tag.id),
      })
    })
    docObj.lines.forEach((line) => {
      const lineInMediaRef = db.collection('media').doc(docObj.parentUid).collection('childMedia').doc(docObj.uid).collection('lines').doc(line.lineId)
      batch.set(lineInMediaRef, {
        name: line.lineName,
        id: line.lineId,
        ref: db.collection('lines').doc(line.lineId),
      })
    })
    docObj.highways.forEach((highway) => {
      const highwayInMediaRef = db.collection('media').doc(docObj.parentUid).collection('childMedia').doc(docObj.uid).collection('highways').doc(highway.id)
      batch.set(highwayInMediaRef, {
        name: highway.name,
        id: highway.id,
        ref: db.collection('highways').doc(highway.id),
      })
    })
    const mediaRef = db.collection('media').doc(docObj.parentUid).collection('childMedia').doc(docObj.uid)
    batch.set(mediaRef, docObj)
    promises.push(batch.commit())
    if (mediaType === mediaTypesEnum.BILLBOARD || mediaType === mediaTypesEnum.DIGITAL_VISION || mediaType === mediaTypesEnum.POSTER) {
      promises.push(
        callAdresAPIsHandler(
          `${docObj.parentUid},${docObj.uid}`,
          docObj.horizontalWidth,
          docObj.verticalWidth,
          docObj.height,
          docObj.direction,
          docObj.latitude,
          docObj.longitude
        )
      )
    }
  }
  return Promise.all(promises)
}

/**
 * csvデータの列に、自動で埋められるカラムの情報を付け加える
 */
const addAutofilledCols = async (dataObj) => {
  if (!dataObj['uid']) {
    const uid = genID()
    dataObj['uid'] = uid
  }
  //年額を0にしておくと、monthlyCostの12倍した値が入る
  if (dataObj['monthlyCost']) dataObj['yearlyCost'] = dataObj['yearlyCost'] ? dataObj['yearlyCost'] : dataObj['monthlyCost'] * 12
  const createdAt = genTimeStamp()
  dataObj['createdAt'] = createdAt
  try {
    const { maleAgeRatio, femaleAgeRatio, ageRatio } = await calcRatio(dataObj)
    dataObj['maleAgeRatio'] = maleAgeRatio
    dataObj['femaleAgeRatio'] = femaleAgeRatio
    dataObj['ageRatio'] = ageRatio
  } catch (error) {
    safeAlert(window, 'メッシュの情報が取得できませんでした。効果可視化が無効の媒体です。')
    dataObj['isLocalMedia'] = true
  }

  const tagResPromise = getTagNames({
    lat: dataObj.latitude,
    lng: dataObj.longitude,
  })

  const lineResPromise = getLineTags({
    lat: dataObj.latitude,
    lng: dataObj.longitude,
  })

  const highwayResPromise = getHighwayTags({
    lat: dataObj.latitude,
    lng: dataObj.longitude,
  })

  const [tagRes, lineTagRes, highwayTagRes] = await Promise.all([tagResPromise, lineResPromise, highwayResPromise])
  dataObj['tags'] = tagRes.data.tags ? tagRes.data.tags : []
  dataObj['lines'] = lineTagRes.data.lines ? lineTagRes.data.lines : []
  dataObj['highways'] = highwayTagRes.data.highways ? highwayTagRes.data.highways : []
  return dataObj
}
