import { taxRate as defaultTaxRate } from 'constants/constants'
import { getCol } from './helpers'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'
import { isJackChild } from 'constants/mediaData'
import { types } from 'constants/types'

export const mediaTypeAdTruck = {
  name: 'アドトラック',
  basicInfoFields: [
    {
      displayedPrefix: '',
      displayedName: '住所',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: getCol('address'),
    },
    {
      displayedPrefix: '',
      displayedName: '車体サイズ(車長)',
      displayedSuffix: 'mm',
      valueType: 'number',
      displayMethod: getCol('vehicleLength'),
    },
    {
      displayedPrefix: '',
      displayedName: '車体サイズ(車幅)',
      displayedSuffix: 'mm',
      valueType: 'number',
      displayMethod: getCol('vehicleWidth'),
    },
    {
      displayedPrefix: '',
      displayedName: '車体サイズ(車高)',
      displayedSuffix: 'mm',
      valueType: 'number',
      displayMethod: getCol('vehicleHeight'),
    },
    {
      displayedPrefix: '',
      displayedName: '車体重量',
      displayedSuffix: 'kg',
      valueType: 'number',
      displayMethod: getCol('vehicleWeight'),
    },
    {
      displayedPrefix: '',
      displayedName: '基本走行エリア',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: getCol('drivingArea'),
    },
    {
      displayedPrefix: '',
      displayedName: '運行時間',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: getCol('drivingHours'),
    },
    {
      displayedPrefix: '',
      displayedName: '費用(1日間)',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => {
        if (media.isPrivate) {
          return '価格非公開'
        } else {
          return `¥${roundAndAddThousandsSeparator(media.dailyCost * media.marginRate * defaultTaxRate, 1)}`
        }
      },
    },
    {
      displayedPrefix: '',
      displayedName: '費用(3日間)',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => {
        if (media.isPrivate) {
          return '価格非公開'
        } else {
          return `¥${roundAndAddThousandsSeparator(media.threeDaysCost * media.marginRate * defaultTaxRate, 1)}`
        }
      },
    },
    {
      displayedPrefix: '',
      displayedName: '費用(1週間)',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => {
        if (media.isPrivate) {
          return '価格非公開'
        } else {
          return `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`
        }
      },
    },
    {
      displayedPrefix: '',
      displayedName: '費用(1ヶ月)',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => {
        if (media.isPrivate) {
          return '価格非公開'
        } else {
          return `¥${roundAndAddThousandsSeparator(media.monthlyCost * media.marginRate * defaultTaxRate, 1)}`
        }
      },
    },
    {
      displayedPrefix: '',
      displayedName: 'ラッピング費用',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: () => {
        return '要問い合わせ'
      },
    },
    {
      displayedPrefix: '',
      displayedName: '出張費',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: () => {
        return '要問い合わせ'
      },
    },
  ],
  effectIndicatorFields: [],
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格／週',
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`),
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: '基本走行エリア',
        displayMethod: (media) => media.drivingArea,
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: '運行時間',
        displayMethod: (media) => media.drivingHours,
        displayedPrefix: '',
        displayedSuffix: '',
      },
    ],
    cartEdit: [
      {
        displayMethod: () => '-',
      },
    ],
  },
  cartFooterContent: {},
  cartRecommendSummary: [],
  propertyFields: [],
  cartConfirmCard: {
    header: [],
    contents: [],
  },
  cartConfirmSummary: [],
  cartConfirmFooter: [],
  culcBundleSummary: (): bundleSummaryDTypeAdTruck => {
    const bundleSummary: bundleSummaryDTypeAdTruck = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForMonth: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForMonth: 0,
      totalConstructionCost: 0,
      totalRecoveryCost: 0,
      totalImpPerMonth: 0,
      totalTargetImpPerMonth: 0,
      cpm: 0,
      tCpm: 0,
      mediaType: 'mediaTypeAdTruck',
    }
    return bundleSummary
  },
  purchasePeriodUnit: 'month',
} as const

export const adTruckColumnDTypes = {
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  drivingArea: types.string,
  drivingHours: types.string,
  hasAudio: types.bool,
  isPrivate: types.bool,
  mediaType: types.enum,
  dailyCost: types.number,
  threeDaysCost: types.number,
  weeklyCost: types.number,
  monthlyCost: types.number,
  vehicleHeight: types.number,
  vehicleLength: types.number,
  vehicleWeight: types.number,
  vehicleWidth: types.number,
  mediaImagePaths: types.stringArrayElem,
  name: types.string,
  note: types.string,
  summary: types.string,
  uid: types.string,
}

// total: 全媒体について合計する
// overall: 掲載費用だけでなく、施工回復費用なので全ての種類の費用を計上する
// forEntirePeriod: 出稿期間全体について費用を算出する
export type bundleSummaryDTypeAdTruck = {
  totalOverallCostForEntirePeriod: number //全ての種類の費用、全ての期間の合計費用
  totalOverallCostForMonth: number //全ての種類の費用、ひと月あたりの費用
  totalPublicationCostForEntirePeriod: number
  totalPublicationCostForMonth: number
  totalConstructionCost: number
  totalRecoveryCost: number
  totalImpPerMonth: number
  totalTargetImpPerMonth: number
  cpm: number
  tCpm: number
  mediaType: 'mediaTypeAdTruck'
}
