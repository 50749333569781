import React, { useContext, createContext, useState, useEffect } from 'react'
// import { AuthContext } from 'components/Auth'
import { AuthContext } from 'providers/Auth'
import { db } from 'FirebaseConfig'
import firebase from 'firebase'
import { cartMediaType } from 'helpers/mediaType/mediaDef'

// import { useCollectionData } from "react-firebase-hooks/firestore";

const CartsAllContext = createContext<{ cartsAll: cartDataType[] }>({ cartsAll: [] })
// const uid = auth.currentUser.uid

// provide carts.all
export type cartDataType = {
  budget: number
  campaignId?: string // このフィールドはdeprecatedだが、DBの更新が終わるまでは消さずに残す
  createAt: Date
  docId?: string
  endDate: firebase.firestore.Timestamp
  endDateMonth: number
  endDateWeek: number
  groupName: string
  id?: string[]
  mediaCount: number
  mediaType: cartMediaType
  startDate: firebase.firestore.Timestamp
  status: number
  targetAge: number[]
  targetLocation: string[]
  targetSex: 'unisex' | 'male' | 'female'
  uid?: string
  userId: string
  isDeleted?: boolean // 以下の２つは削除されたカートに対してのみ設定される
  deletedAt?: firebase.firestore.Timestamp
}

const CartsAllProvider = ({ children }) => {
  const [cartsAll, setCartsAll] = useState<cartDataType[]>([])
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (currentUser) {
      db.collection('cart')
        .where('userId', '==', currentUser.uid)
        .onSnapshot((query) => {
          const data: cartDataType[] = []
          query.forEach((d) => data.push({ ...(d.data() as cartDataType) }))
          setCartsAll(data)
        })
    }
  }, [currentUser])

  return <CartsAllContext.Provider value={{ cartsAll }}>{children}</CartsAllContext.Provider>
}

export { CartsAllContext, CartsAllProvider }
