import React from 'react'
import MediaDetailTable from '../../tables/MediaDetailTable'
import { MediaCarousel } from '../../../molecules/MediaCarousel'
import { Button, Dimmer, Segment, Header } from 'semantic-ui-react'
import ImpressionFigure from '../../tables/ImpressionFigure'
import Spacer from '../../../atoms/Spacer'
import paths from '../../../../constants/paths'
import TagList from '../../tag/TagList'
import { convertValueToText } from 'helpers/mediaType/helpers'
import { useHistory } from 'react-router'
import { isJackChild } from 'constants/mediaData'

const MapSearchMediaDetailSegments = ({ media, isLogined, isJackChildList = false }) => {
  const history = useHistory()

  return (
    <>
      {isJackChildList && (
        <div style={{ gridColumn: '1/3', gridRow: '1' }}>
          <Segment basic>
            <b>{media.name}</b>
          </Segment>
        </div>
      )}
      <div style={{ gridColumn: '1/3', gridRow: '1' }}>
        <Segment basic>
          {isJackChild(media) && <Button compact>エリアジャック専用</Button>}
          <Button active compact color="red">
            {convertValueToText(media.mediaType)}
          </Button>
          <div style={{ margin: '1rem 0' }}>
            <TagList tags={media.tags} color="#f39800" textColor="white" />
            <TagList tags={media.lines} color="#5cBBcf" textColor="white" />
            <TagList tags={media.highways} color="#5cBBcf" textColor="white" />
          </div>
        </Segment>
      </div>
      <div style={{ gridColumn: '1/3', gridRow: '2' }}>
        <MediaCarousel imageURLArray={media.mediaImagePaths} />
      </div>
      <div style={{ gridColumn: '1/3', gridRow: '3' }}>
        <br />
        <b className="mapSearchMediaDetail__mediaDetailTitle">概要</b>
        <Segment basic style={{ whiteSpace: 'normal', lineHeight: '1.5rem' }}>
          {media.summary}
        </Segment>
        <b className="mapSearchMediaDetail__mediaDetailTitle">基本情報</b>
        <MediaDetailTable media={media} />
      </div>
      <Spacer height={3} />
      {media.mediaType !== 'mediaTypeAdTruck' && !media.isLocalMedia && (
        <div style={{ gridColumn: '1/3', gridRow: '4' }}>
          <b className="mapSearchMediaDetail__mediaDetailTitle">効果</b>
          <Dimmer.Dimmable blurring dimmed={!isLogined}>
            <Dimmer active={!isLogined} inverted>
              <div style={{ gridColumn: '1/3', gridRow: '4' }}>
                <Header as="p" style={{ color: 'black' }}>
                  ※詳細をご覧頂くには会員登録が必要です。
                </Header>
                <Button
                  color="orange"
                  onClick={() => {
                    history.push(`${paths.signin}`)
                  }}
                >
                  会員登録/ログイン
                </Button>
              </div>
            </Dimmer>
            <ImpressionFigure media={media} isMinimized={true} />
          </Dimmer.Dimmable>
        </div>
      )}
      <div style={{ gridColumn: '1/3', gridRow: '5' }}>
        <b className="mapSearchMediaDetail__mediaDetailTitle">備考</b>
        <Segment basic>
          <p style={{ whiteSpace: 'normal' }}>{media.description}</p>
        </Segment>
      </div>
      {media.mediaType === 'mediaTypeJack' && (
        <div style={{ gridColumn: '1/3', gridRow: '6' }}>
          <b className="mapSearchMediaDetail__mediaDetailTitle">エリアジャックに含まれる広告媒体一覧</b>
          <Segment>
            {media.childMedia.map((element) => {
              return <MapSearchMediaDetailSegments key={element.uid} media={element} isLogined={isLogined} isJackChildList={true} />
            })}
          </Segment>
        </div>
      )}
    </>
  )
}

export default MapSearchMediaDetailSegments
