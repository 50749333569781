export const mediaTypesEnum = {
  BILLBOARD: 'mediaTypeBillboard',
  DIGITAL_VISION: 'mediaTypeVision',
  POSTER: 'mediaTypePoster',
  MANSION_SIGNAGE: 'mediaTypeMansionSignage',
  LEAFLET: 'mediaTypeLeaflet',
  JACK: 'mediaTypeJack',
  AD_TRUCK: 'mediaTypeAdTruck',
}

export const mediaTypeDisplayNames = {
  [mediaTypesEnum.BILLBOARD]: 'ビルボード',
  [mediaTypesEnum.DIGITAL_VISION]: 'ビジョン',
  [mediaTypesEnum.POSTER]: 'ポスター',
  [mediaTypesEnum.MANSION_SIGNAGE]: 'マンションサイネージ',
  [mediaTypesEnum.LEAFLET]: 'リーフレット',
}
