import { getPeriodUnit } from '../mediaType/mediaTypeBillboard'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { cartDataType } from 'providers/CartsAll'
import { mediaBookedDataType } from 'providers/MediaBookedAll'
import { isSameJackGroup } from '../isSameJack'

/**
 * カートの条件に当てはまる媒体を抽出して返却する
 */
export const mediaListGenerator = (medias: AdviceMediaDataType[], cart: cartDataType, mediaBooked: mediaBookedDataType[], mediasAvailable) => {
  let tmpMedias: AdviceMediaDataType[] = medias
  if (cart.targetLocation && cart.targetLocation.length && tmpMedias) {
    tmpMedias = tmpMedias.filter((row) => {
      let frag = false
      cart.targetLocation.forEach((targetLocation) => {
        if (row.address.indexOf(targetLocation) !== -1) {
          frag = true
        }
      })
      if (frag) {
        return row
      } else {
        return false
      }
    })
    //tmpMediasに入っているジャック媒体
    const tmpMediasJack = tmpMedias.filter((media) => media.mediaType === 'mediaTypeJack' || isJackChild(media))

    //mediasAvailableに入っている、tmpMediasJackと同じジャックグループの媒体を抽出し、tmpMediasJackとの重複するものを削除
    tmpMedias = tmpMedias.concat(
      Array.from(
        new Set(
          tmpMediasJack
            .map((targetJackMedia) => {
              return medias.filter((media) => isSameJackGroup(media, targetJackMedia.uid, mediasAvailable))
            }) //mediasAvailableに入っている、tmpMediasに入っているジャック媒体の親媒体か同じジャックグループの子媒体を抽出
            .flat()
        )
      ).filter((media) => !tmpMediasJack.some((jack) => jack.uid === media.uid))
    )
  }

  if (cart.startDate && cart.endDate && mediaBooked) {
    tmpMedias = tmpMedias.filter((media) => {
      let dateFrag = true
      mediaBooked.forEach((item) => {
        if (item.mediaId === media.uid && item.isBooked) {
          if (cart.startDate.seconds < item.date.seconds && cart.endDate.seconds > item.date.seconds) {
            dateFrag = false
          }
        }
      })
      if (dateFrag) {
        return media
      }
    })
  }
  //カートのmediaTypeと同じmediaTypeを持つ媒体を抽出

  tmpMedias = tmpMedias.filter((media) => {
    if (media.mediaType === 'mediaTypeJack') {
      return media.childMedia.some((child) => child.mediaType === cart.mediaType)
    }
    return media.mediaType === cart.mediaType
  })
  tmpMedias.forEach((media) => {
    media.isAtCart = !!(cart.id && cart.id.includes(media.uid))
  })
  //billboardの年額扱いの媒体を除く
  tmpMedias = tmpMedias.filter(
    //billboardのうち年額扱いのみの媒体は除く。ただし、掲載月数が12の倍数の時のみ、そのような媒体も扱える。
    (media) => !(!isJackChild(media) && media.mediaType === 'mediaTypeBillboard' && getPeriodUnit(media) === 'FixedOnlyYearly' && cart.endDateMonth % 12 !== 0)
  )
  return tmpMedias
}
