import React, { useCallback, useContext, useMemo, useState } from 'react'
import firebase from 'firebase'
import { Segment, Button } from 'semantic-ui-react'
import Loading from 'components/atoms/Loading'
import { useMedias } from 'providers/Medias'
import './index.css'
import { UserContext } from 'providers/User'
import MapSearchMediaDetailSegments from '../MapSearchMediaDetailSegments'
import { openMediaPageLink } from 'helpers/openMediaPageLink'

const getMediaPDF = firebase.functions().httpsCallable('getMediaPDF')
const recordDownloadPDF = firebase.functions().httpsCallable('recordDownloadPDF')

type props = {
  // TODO: any型を排除する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediaId: string
  isLogined: boolean
}

const MapSearchMediaDetail = (props: props) => {
  const mediaList = useMedias()
  const media = useMemo(() => {
    const targetMedia = mediaList.find((media) => media.uid === props.mediaId)
    if (targetMedia) {
      return targetMedia
    } else {
      return null
    }
  }, [mediaList, props.mediaId])
  const { user } = useContext(UserContext)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const isUnableToDownloadPDF = useMemo(() => {
    return !props.isLogined || isDownloading
  }, [isDownloading, props.isLogined])

  const clickExternalLinkHandler = useCallback(() => {
    media && openMediaPageLink(media)
  }, [media])

  const downloadPDFHandler = useCallback(async () => {
    setIsDownloading(true)
    const res =
      media &&
      (await getMediaPDF({
        mediaID: media.uid,
      }))
    if (res && res.data.status === 200 && window) {
      window.open(res.data.fileDownloadURL)
      user &&
        (await recordDownloadPDF({
          mediaId: media.uid,
          userId: user.uid,
        }))
    } else {
      if (window) {
        window.alert('pdfが正常に作成されませんでした。')
      }
      console.error(res && res.data.message)
    }
    setIsDownloading(false)
  }, [media])

  return (
    <>
      {!media ? (
        <Loading />
      ) : (
        <div>
          <Segment>
            <b>{media.name}</b>
          </Segment>
          <div style={{ display: 'grid' }} className="mapSearchMediaDetail__scrollMediaDetail">
            <MapSearchMediaDetailSegments media={media} isLogined={props.isLogined} />
          </div>
          <div style={{ height: '54px' }} className="mapSearchMediaDetail__cartDetailButtonWrapper">
            <div>
              <Button onClick={clickExternalLinkHandler} color="teal">
                媒体の詳細ページへ
              </Button>
              {media.mediaType !== 'mediaTypeAdTruck' && (
                <Button onClick={downloadPDFHandler} disabled={isUnableToDownloadPDF} color="teal">
                  PDFでダウンロード
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MapSearchMediaDetail
